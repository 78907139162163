<template>
  <b-card
      class="storesales-edit-wrapper"
  >
    <!-- form -->
    <b-form id="storesalesForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="负责人"
              label-for="user_id"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="user_id"
                size="sm"
                v-model="userName"
                v-b-modal.modal-select-user
                placeholder="点击搜索负责人"
                readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="是否默认"
              label-for="is_default"
              label-size="sm"
              class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch
                inline
                id="is_default"
                v-model="storesales.is_default"
                value="1"
                unchecked-value="0"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>

      <b-modal
          id="modal-select-user"
          ok-only
          ok-title="确认"
          @ok="onSelectUser"
          cancel-title="取消"
          centered
          size="lg"
          title="选择姓名"
      >
        <user-select
            ref="myUserSelect">
        </user-select>

      </b-modal>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import storesalesStore from './storesalesStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import userStore from "@/views/apps/user/userStore";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    UserSelect,

  },
  data() {
    return {
      id: ref(0),
      storesales: ref({}),
      storeId: 0,
      userName: '',
      type:0,
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('storesales')) store.registerModule('storesales', storesalesStore)
    if (!store.hasModule('user')) store.registerModule('user', userStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('storesales')) store.unregisterModule('storesales')
      if (store.hasModule('user')) store.unregisterModule('user')
    })

    const edit = function () {
      store.dispatch('storesales/edit', {id: this.id}).then(res => {
        this.storesales = res.data.data
        this.userName = this.userId = getCodeLabel("user", this.storesales.user_id)
      })
    }

    const view = function () {
      store.dispatch('storesales/view', {id: this.id}).then(res => {
        this.storesales = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      this.storesales.store_id = this.storeId
      this.storesales.type = this.storesales.type==null?this.type:this.storesales.type
      if (this.storesales.user_id === undefined || this.storesales.user_id === "") {
        toast.error("请选择负责人!")
        return false
      }
      if (this.storesales.is_default == null) {
        this.storesales.is_default = 0
      }
      store.dispatch('storesales/save', this.storesales).then(res => {
        if (res.data.code == 0) {
          toast.success('数据已保存!')
          this.$router.push({name: 'apps-storesales-list', query: {storeId: this.storeId,type:this.storesales.type==null?this.type:this.storesales.type}});
        } else {
          toast.error(res.data.data)
        }

      })
    }

    const onSelectUser = function (bvModalEvt) {
      let checked = this.$refs.myUserSelect.getSelected()[0];
      this.userName = checked.full_name
      this.storesales.user_id = checked.user_id
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onSelectUser,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.storeId = this.$route.query.storeId || 0;
    this.type = this.$route.query.type || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
